import React from "react";
import API from "./../Components/api";
import ReportRow from "./ReportRow";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import LoadingImage from "./LoadingImage";
import TableRow from "@material-ui/core/TableRow";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import SearchByCourse from "./SearchByCourse";

export const CONSTANTS = {
  NUMBER_OF_TOP_COURSES: 6,
  REPORTS_WITH_TOP_COURSES_AND_COUNT: ["GAI Spectrum Map", "GA Spectrum Map"],
  REPORTS_WITH_NO_COURSES: ["GA IDA Summary", "Course GA - Weeks"]
};

class Report extends React.Component {
  constructor(props) {
    super(props);
    this.containerEl = null;
    this.externalWindow = null;

    this.state = {
      reportName: props.reportName,
      academicYearId: props.academicYearId,
      programId: props.programId || null,
      coursePlanId: props.coursePlanId || null,
      errorMessage: null,
      isLoading: true,
      reportHtml: null,
      highlightedRow: null
    };

    this._highlightCell = this._highlightCell.bind(this);
  }

  componentDidMount() {
    this.getData();
  }

  _processApiResponseError = err => {
    let newMessage;

    if (err.response.status === 400) newMessage = err.response.data.value;
    else newMessage = "Error: Unable to retrieve data.";

    this.setState({
      errorMessage: newMessage,
      isLoading: false
    });
  };

  _processApiResponse = response => {
    if (response.status === 204) {
      this.setState({
        errorMessage: "No data to report.",
        isLoading: false
      });
      return;
    }

    this.setState({
      reportHtml: this.processJSON(response.data),
      isLoading: false
    });
  };

  _toHtml = theData => {
    let footNoteInfoText;
    let footNoteInfo;
    let tableHead = [<ReportRow key={0} dataRow={theData[0]} />];

    if (
      this.state.reportName === "GA Spectrum Map" ||
      this.state.reportName === "GA - IDA Spectrum Map" ||
      this.state.reportName === "GAI Spectrum Map" ||
      this.state.reportName === "GAI - IDA Spectrum Map"
    ) {
      footNoteInfoText =
        " * Values in table are hours taught or AU’s calculated for a particular GA, GAI or IDA.";
    } else if (this.state.reportName === "GA IDA Summary") {
      footNoteInfoText = `* Values in table are hours taught or AU’s calculated and % in the
          tables are the ratio of that particular element divided by all the
          AU’s mapped in the 4 year program`;
    }
    else if (this.state.reportName === "AU Summary / Components") {
        footNoteInfoText = `*The CEAB reduced criterion 3.4.6 from a Total AU count of 1950 to 1850 in the 2020 revision of the Criteria and Procedures`;
      }

    footNoteInfo = (
      <div className="foot-note-info medium-font">{footNoteInfoText}</div>
    );

    //if table needs two rows for the head
    if (theData[0][0].title === "") {
      tableHead.push(<ReportRow key={1} dataRow={theData[1]} />);
    }

    return (
      <React.Fragment>
        <Table
          id="reportTable"
          cellSpacing="0"
          style={{ borderCollapse: "collapse" }}
        >
          <TableHead>
           {tableHead}
          </TableHead>
          <TableBody>
            {theData.slice(tableHead.length).map((row, index) => (
              <ReportRow key={index + tableHead.length} dataRow={row} />
            ))}
            {/*Adds search input as the last row of the table*/}
            <TableRow key="searchBarRow">
              <TableCell
                colSpan={
                  CONSTANTS.REPORTS_WITH_TOP_COURSES_AND_COUNT.indexOf(
                    this.state.reportName
                  ) > -1
                    ? 6
                    : 5
                }
              />
              <TableCell className="searchBarCell" colSpan={5}>
                {this._renderSearchForm()}
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
        {footNoteInfo}
      </React.Fragment>
    );
  };

  getData() {
    if (this.state.reportName === "AU Summary / Components") {
      API.get(`AdminReport/AUSummary(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "GA Spectrum Map") {
      API.get(`AdminReport/GASpectrumMap(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "GAI Spectrum Map") {
      API.get(`AdminReport/GAISpectrumMap(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "GA - IDA Spectrum Map") {
      API.get(`AdminReport/GAIDASpectrumMap(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "GAI - IDA Spectrum Map") {
      API.get(`AdminReport/GAIIDASpectrumMap(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "GA IDA Summary") {
      API.get(`AdminReport/GAIDASummary(academicYearId=${this.state.academicYearId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    } else if (this.state.reportName === "Course GA - Weeks") {
      API.get(`AdminReport/CourseGAWeeks(coursePlanId=${this.state.coursePlanId},programId=${this.state.programId})`)
        .then(this._processApiResponse)
        .catch(this._processApiResponseError);
    }
  }

  processJSON = jsonData => {
    //Make row header text vertical
    //If less than 3 rows return without doing anything
    if (jsonData.length < 3) return jsonData;

    //Row merge first 1 columns only
    for (let i = 1; i >= 0; i--) {
      let prevCell = null;

      //Start row merging 2 rows down
      for (let j = 1; j < jsonData.length; j++) {
        if (prevCell === null) {
          prevCell = jsonData[j - 1][i];
        }

        if (prevCell === null || prevCell === undefined) continue;

        let curCell = jsonData[j][i];
        if (curCell.title != "" && curCell.title != null) {
          if (curCell.title === prevCell.title) {
            if (!prevCell.hasOwnProperty("RowSpan")) {
              prevCell["RowSpan"] = 2;
            } else prevCell["RowSpan"] = prevCell["RowSpan"] + 1;

            jsonData[j].splice(i, 1);
          } else prevCell = null;
        }
      }
    }

    return jsonData;
  };

  _highlightCell(newReportTable, highlightedRow) {
    this.setState({ reportHtml: newReportTable, highlightedRow:  highlightedRow});
  }

  _renderSearchForm() {
    //only shows search bar if current report has list of courses
    if (CONSTANTS.REPORTS_WITH_NO_COURSES.indexOf(this.state.reportName) > -1)
      return;

    return (
      <React.Fragment>
        <SearchByCourse
          onSearchExecuted={this._highlightCell}
          reportData={this.state.reportHtml}
          highlightedRow={this.state.highlightedRow}
          numberOfTopCourses={CONSTANTS.NUMBER_OF_TOP_COURSES}
          hasTopCourses={
            CONSTANTS.REPORTS_WITH_TOP_COURSES_AND_COUNT.indexOf(
              this.state.reportName
            ) > -1
          }
        />
      </React.Fragment>
    );
  }

  render() {
    if (this.state.isLoading) {
      return (
        <div>
          <LoadingImage />
        </div>
      );
    }

    if (this.state.errorMessage != null) {
      return (
        <h4>
          <span style={{ fontFamily: "Helvetica, Arial, sans-serif" }}>
            {this.state.errorMessage}
          </span>
        </h4>
      );
    }

    return (
      <React.Fragment>{this._toHtml(this.state.reportHtml)}</React.Fragment>
    );
  }
}

export default Report;
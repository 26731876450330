// Using: https://github.com/paulhodel/jexcel

import React from "react";
import Button from '@material-ui/core/Button';
import axios from 'axios';
import { Error as ErrorIcon } from "@material-ui/icons";

import "./AssessmentMarks.css"
import AlertDialog from "./../AlertDialog"
import OneStepBack from "./../OneStepBack"
import Jexcel from "./../Components/Jexcel";
import API from "./../Components/api";
import WaitSpinner from "./../Components/WaitSpinner"

class Marks extends React.Component {

    constructor(props) {
        super(props);

        this._saveMarks = this._saveMarks.bind(this);
        this._onJExcelChange = this._onJExcelChange.bind(this);
        this._jexcelOnPaste = this._jexcelOnPaste.bind(this);
        this._renderStatusBadge = this._renderStatusBadge.bind(this);
        this._promptClearMarks = this._promptClearMarks.bind(this);
        this._deleteMarks = this._deleteMarks.bind(this);
        this._hideDeletePrompt = this._hideDeletePrompt.bind(this);
        this._autoSaveDelay = 2000; // 2 seconds
        this._saveMarksTimeout = null;
        this._gridKey = new Date().getTime();

        this.state = {
            assessmentMarks: [],
            isGettingData: true,
            assessmentId: this.props.match.params.id1,
            courseCode: "",
            courseNumber: "",
            assessmentName: "",
            jexcelColumns: [],
            jexcelData: [],
            markIdList: [],
            errorMessage: null,
            saving: false,
            saveQueued: false,
            promptClearMarks: false
        }
    }

    componentDidMount() {
        this._getAssessmentInfo();
        this._getAssessmentMarks();
    }

    _renderStatusBadge = () => {
        if (this.state.saving || this.state.saveQueued)
            return <span style={{ fontSize: "85%", fontStyle: "italic" }}>Saving...</span>

        if (!this.state.saving && !this.state.saveQueued)
            return <span style={{ fontSize: "85%", fontStyle: "italic" }}>All Changes Saved</span>

        return (
            <React.Fragment>
                <ErrorIcon className="ehs-error-icon"></ErrorIcon>
                <span style={{ position: "relative", top: "3px" }}>{this.state.lastSaveError}</span>
            </React.Fragment>);
    }

    _saveMarks(forceSave) {
        if (!forceSave && !this._saveMarksTimeout)
            return;

        var that = this;

        clearTimeout(this._saveMarksTimeout);
        this._saveMarksTimeout = null;

        if (this.state.saving) {
            this._saveMarksTimeout = setTimeout(function () { that._saveMarks() }, that._autoSaveDelay);
            return;
        }

        this.setState({
            saving: true
        }, function () {
            var studentAssessmentMarks = [];
            var studentGaiMarks = [];

            for (let i = 0; i < this.state.jexcelData.length; i++) {
                var studentAssessmentMark = {
                    "StudentId": this.state.jexcelData[i][0],
                    "AssessmentId": this.state.assessmentId,
                    "StudentMark": this.state.jexcelData[i][3] === "" ? null : this.state.jexcelData[i][3]
                }

                for (let j = 0; j < this.state.markIdList.length; j++) {
                    var studentGaiMark = {
                        "StudentId": this.state.jexcelData[i][0],
                        "AssessmentDetailId": this.state.markIdList[j],
                        "StudentMark": this.state.jexcelData[i][j + 4] === "" ? null : this.state.jexcelData[i][j + 4]
                    }

                    studentGaiMarks.push(studentGaiMark);
                }

                studentAssessmentMarks.push(studentAssessmentMark);
            }

            var objToPost = {
                "AssessmentId": this.state.assessmentId,
                "StudentAssessmentMarks": studentAssessmentMarks,
                "StudentAssessmentGaiMarks": studentGaiMarks
            }

            API.post(`Assessment/SaveStudentMarks`, objToPost)
                .then(res => {
                    if (res.status === 200) {
                        that.setState({
                            errorMessage: null
                        });
                    }
                    // error happened
                    else {
                        that._getAssessmentMarks();
                    }
                })
                .catch(err => {
                    that._getAssessmentMarks();
                    that.setState({
                        errorMessage: err.response &&
                            err.response.data &&
                            err.response.data.value ? err.response.data.value : "Unable to save marks."
                    });
                })
                .finally(function () {
                    if (that._saveMarksTimeout != null)
                        that.setState({ saving: false, saveQueued: true });
                    else
                        that.setState({ saving: false, saveQueued: false });
                })
        });
    }

    _deleteMarks() {
        let newJExcelData = [...this.state.jexcelData];
        let that = this;

        for (let i = 0, iMaxLength = newJExcelData.length; i < iMaxLength; i++) {
            for (let j = 3, jMaxLength = newJExcelData[i].length; j < jMaxLength; j++) {
                newJExcelData[i][j] = null;
            }
        }

        this.setState({
            jexcelData: newJExcelData,
            promptClearMarks: false
        },
            that._saveMarks(true));
    }

    _promptClearMarks() {
        this.setState({ promptClearMarks: true });
    }

    _hideDeletePrompt() {
        this.setState({ promptClearMarks: false });
    }

    render() {
        if (this.state.isGettingData)
            return <WaitSpinner></WaitSpinner>;

        if (this.state.promptClearMarks) {
            return (
                <AlertDialog
                    title="Delete Marks?"
                    content="Are you sure you want to delete all marks?"
                    confirmButtonText="Delete"
                    onOkClose={this._deleteMarks}
                    onCancelClose={this._hideDeletePrompt}>
                </AlertDialog>
            )
        }

        var options = {
            data: [...this.state.jexcelData],
            columns: [...this.state.jexcelColumns],
            allowInsertRow: false,
            allowManualInsertRow: false,
            allowInsertColumn: false,
            allowManualInsertColumn: false,
            allowDeleteRow: false,
            allowDeleteColumn: false,
            allowRenameColumn: false,
            tableOverflow: true,
            tableHeight: '600px',
            onchange: this._onJExcelChange.bind(this),
            onpaste: this._jexcelOnPaste.bind(this)
        };

        let mainStyle = "m-portlet m-portlet--creative m-portlet--bordered-semi widget-specs m-portlet--first";

        var clearButton = null;
        if (!this._isAssessmentLocked()) {
            clearButton = <Button onClick={this._promptClearMarks} className="embers whiteText">Clear Table</Button>;
        }

        return (
            <React.Fragment>
                <div className="row no-margin">
                    <div className="col-6 no-padding" onClick={this._saveMarks}><OneStepBack disabled={this.state.saving || this.state.saveQueued}  ></OneStepBack></div>
                    <div className="col-6" style={{ textAlign: "right" }}>{this._showAssessmentLockedMessage()}</div>
                </div>
                <div className="m-page--fluid m--skin- m-content--skin-light2 m-header--fixed m-header--fixed-mobile m-aside-left--enabled m-aside-left--skin-dark m-aside-left--offcanvas m-footer--push m-aside--offcanvas-default full-height">
                    <div className="m-grid m-grid--hor m-grid--root m-page full-height">
                        <div className="m-grid__item m-grid__item--fluid m-grid m-grid--ver-desktop m-grid--desktop m-body">
                            <div className="m-grid__item m-grid__item--fluid m-wrapper">
                                <div className="m-content">
                                    <div className="row">
                                        <div className="col-lg-12">
                                            <div className={mainStyle}>
                                                <div className="m-portlet__head">
                                                    <div className="m-portlet__head-caption">
                                                        <div className="m-portlet__head-title">
                                                            <h2 className="m-portlet__head-label m-portlet__head-label--warning no-margin-top ink">
                                                                <span className="no-wrap course-title">{this.state.courseCode} {this.state.courseNumber} ({this.state.assessmentName})</span>
                                                            </h2>
                                                            <h3 key={new Date().getTime()} className="m-portlet__head-text">
                                                                <span>{this._renderStatusBadge()}</span>
                                                            </h3>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="m-portlet__body" style={{ overflow: "auto" }}>
                                                    <div>
                                                        <Jexcel key={this._gridKey} options={options} />
                                                    </div>
                                                    {clearButton}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </React.Fragment>
        )
    }

    _isAssessmentLocked = () => {
        return this.state.isAssessmentComplete && this.state.isAssessmentComplete === true;
    }

    _showAssessmentLockedMessage() {
        if (this._isAssessmentLocked())
            return <span className="spacing-top20">Marks cannot be edited because the Assessment is complete.</span>;

        if (this.state.errorMessage)
            return <span className="spacing-top20 ehs-error">Error: {this.state.errorMessage}</span>;

        return null;
    }

    _jexcelOnPaste(obj, payload) {
        var seenCells = [];

        for (let i = 0; i < payload.length; i++) {
            if (seenCells.includes(":row:" + payload[i].row + ":col:" + payload[i].col)) {
                clearTimeout(this._saveMarksTimeout);
                this._saveMarksTimeout = null;
                this.setState({ errorMessage: "Cannot paste more rows than Students (" + this.state.assessmentMarks.length + ")" });
                break;
            }
            else {
                seenCells.push(":row:" + payload[i].row + ":col:" + payload[i].col);
            }
        }

        this._applyInitialStyling();
    }
      
    _onJExcelChange(obj, cell, val) {
        let that = this;
        let mark = cell.textContent;

        let rowIndex = cell.getAttribute('data-y');
        let colIndex = cell.getAttribute('data-x');

        // Get max marks for validation
        let maxMark = (colIndex == 3)
            ? this.state.assessmentMaxMark  // Column 3 is the main assessment
            : this.state.gaiMaxMarks[colIndex - 4]; // GAI marks start at col 4

        if (isNaN(mark) || mark >= 1000 || mark <= -1000) {
            this._gridKey = new Date().getTime();
            mark = null;
        } else if (mark === '') {
            mark = null;
        }

        let newJExcelData = [...this.state.jexcelData];

        if (mark === null) {
            newJExcelData[rowIndex][colIndex] = mark;
        } else {
            newJExcelData[rowIndex][colIndex] = Number.parseFloat(mark);
        }

        this.setState({ jexcelData: newJExcelData });

        if (!this._saveMarksTimeout) {
            this._saveMarksTimeout = setTimeout(() => that._saveMarks(), that._autoSaveDelay);
        }

        this._applyInitialStyling();
    }

    _getAssessmentInfo() {
        API.get(`Assessment/AssessmentInfo(assessmentId=${this.state.assessmentId})`)
            .then(res => {
                this.setState({
                    courseCode: res.data.CourseCode,
                    courseNumber: res.data.CourseNumber,
                    assessmentName: res.data.AssessmentTitle
                });
            }).catch(err => {
                if (API.isCancel(err))
                    return;
            });
    }

    _getAssessmentMarks() {
        var that = this;

        var marksData = API.get(`Assessment/AssessmentMarks(assessmentId=${this.state.assessmentId})`);
        var assessmentData = API.get(`Assessment(${this.state.assessmentId})`);

        axios.all([marksData, assessmentData])
            .then(axios.spread(function (res, assessmentInfo) {
                let data = res.data;
                let isAssessmentComplete = assessmentInfo.status === 200 && assessmentInfo.data && assessmentInfo.data.IsComplete;
                let assessmentMaxMark = that.props.location.state.assessmentMaxMark;

                var jexcelColumns = [
                    { type: 'hidden', title: 'Student Id', readOnly: true },
                    { type: 'text', title: 'Student Id', width: "100px", readOnly: true },
                    { type: 'text', title: 'Student Name', width: "150px", readOnly: true },
                    { type: 'numeric', title: "Assessment Score (out of " + that.props.location.state.assessmentMaxMark + ")", width: "220px", readOnly: isAssessmentComplete },
                ];

                let jexcelData = [];
                let markIdList = [];
                let gaiMaxMarks = [];

                // for all Students
                for (let idx = 0; idx < data.value.length; idx++) {
                    let curRow = data.value[idx];
                    // get all columns
                    if (idx === 0) {
                        for (let idx1 = 0; idx1 < curRow.Marks.length; idx1++) {
                            let gaiMaxMark = curRow.Marks[idx1].OutOf;
                            gaiMaxMarks.push(gaiMaxMark);

                            jexcelColumns.push({ type: 'numeric', title: "GAI " + curRow.Marks[idx1].GAINumber.toString() + " (out of " + curRow.Marks[idx1].OutOf + ")", width: "150px", readOnly: isAssessmentComplete });
                            markIdList.push(curRow.Marks[idx1].AssessmentDetailsId);
                        }
                    }

                    var jexcelRow = [curRow.StudentId, curRow.StudentNumber, curRow.StudentFullName, curRow.Score];

                    let marksLength = curRow.Marks.length;

                    for (let idx1 = 0; idx1 < marksLength; idx1++) {
                        jexcelRow.push(curRow.Marks[idx1].Mark);
                    }

                    jexcelData.push(jexcelRow);
                }

                that.setState({
                    isAssessmentComplete: isAssessmentComplete,
                    assessmentMarks: data.value,
                    isGettingData: false,
                    jexcelData: jexcelData,
                    jexcelColumns: jexcelColumns,
                    markIdList: markIdList,
                    assessmentMaxMark: assessmentMaxMark,
                    gaiMaxMarks: gaiMaxMarks
                }, () => {
                    setTimeout(() => that._applyInitialStyling(), 0); // Ensure the table is rendered before applying styles
                });
            }
            ));
    }

    _applyInitialStyling() {
        let table = document.querySelector('.jexcel'); // Get the JExcel table
        if (!table) return;

        let rows = table.querySelectorAll('tr');

        rows.forEach(row => {
            let totalScoreCell = row.querySelector('td[data-x="3"]'); // Get the assessment score cell
            let gaiCells = row.querySelectorAll('td[data-x]');

            let gaiSum = 0;

            gaiCells.forEach(td => {
                let colIndex = parseInt(td.getAttribute('data-x'));
                let mark = td.textContent.trim() === '' || isNaN(parseFloat(td.textContent)) ? 0 : parseFloat(td.textContent);

                if (colIndex >= 4) { // GAI marks
                    let maxMark = this.state.gaiMaxMarks[colIndex - 4];
                    let isValid = !isNaN(mark) && mark >= 0 && mark <= maxMark;

                    // Apply styling for individual GAI cell
                    td.style.backgroundColor = isValid ? "white" : "#ffcc80"; // Warning background
                    td.style.color = isValid ? "black" : "#b35900"; // Warning text

                    // Always add valid marks to the sum
                    if (!isNaN(mark) && mark !== null) {
                        gaiSum += mark;
                    }
                }
            });

            // Ensure total score validation happens **after** gaiSum is fully calculated
            if (totalScoreCell) {
                let totalScore = isNaN(parseFloat(totalScoreCell.textContent.trim())) ? 0 : parseFloat(totalScoreCell.textContent.trim());
                let isTotalValid = (isNaN(totalScore) || gaiSum <= totalScore) && gaiSum <= this.state.assessmentMaxMark && totalScore <= this.state.assessmentMaxMark;

                totalScoreCell.style.backgroundColor = isTotalValid ? "white" : "#ffcc80"; // White for valid, light orange for invalid
                totalScoreCell.style.color = isTotalValid ? "black" : "#b35900"; // Black for valid, dark orange for invalid
            }
        });
    }
}

export default Marks;
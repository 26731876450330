import React from 'react';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { withStyles } from '@material-ui/core/styles';
import { ValidatorForm, TextValidator } from 'react-material-ui-form-validator';
import Switch from '@material-ui/core/Switch';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import Dialog from '@material-ui/core/Dialog';

import API from './../Components/api';

const styles = (theme) => ({
    root: {
        width: '100%',
        overflowX: 'auto',
    },
    table: {
        maxWidth: '100%',
    },
    inputField: {
        marginLeft: theme.spacing.unit,
        marginRight: theme.spacing.unit,
        width: 200,
    },
});

class EditUserDialog extends React.Component {
    constructor(props) {
        super(props);
        let personIdLoggedIn = localStorage['PersonId'];

        this.state = {
            firstName: props.value.FirstName,
            lastName: props.value.LastName,
            email: props.value.EMail,
            userRoles: props.value.UserRoles,
            personNumber: props.value.PersonNumber,
            oldPersonNumber: props.value.PersonNumber,
            personNumberFieldType: 'password',
            userStatus: props.value.UserStatus,
            personId: props.value.PersonId,
            roleValidationError: false,
            isActive:
                props.value.UserStatus.includes('Active') ||
                    props.value.UserStatus.includes('Invited')
                    ? true
                    : false,
            editingSelf: personIdLoggedIn == props.value.PersonId,
        };
    }

    _handleCancel = () => {
        this.props.onClose(false);
    };

    _handleEditSubmit = () => {
        var that = this;
        var userObject = {
            FirstName: this.state.firstName,
            LastName: this.state.lastName,
            EMail: this.state.email,
            UserRoles: this.state.userRoles,
            UserStatus: this.state.isActive ? 'Active' : 'Inactive',
            PersonNumber: this.state.personNumber,
            PersonId: this.state.personId,
        };

        API.post(`Auth/EditUser`, { User: userObject })
            .then(function (res) {
                that.props.onClose(true);
            })
            .catch(function (err) {
                alert("Couldn't save changes to user please try again.");
            });
    };

    _validateRoles() {
        this.setState({
            roleValidationError: !this.state.userRoles.filter((x) => x.Checked)
                .length
                ? true
                : false,
        });
    }

    _handleChange = (name) => (event) => {
        this.setState({
            [name]: event.target.value,
        });
    };

    _handleStatusChange = (name) => (event) => {
        this.setState({
            [name]: event.target.checked,
        });
    };

    _handleRoleChange = (name) => (event) => {
        this.state.userRoles.find((x) => x.RoleName === name).Checked =
            event.target.checked;

        let that = this;
        this.setState(
            {
                userRoles: [...this.state.userRoles],
            },
            function () {
                that._validateRoles();
            }
        );
    };

    _personNumberFocused = () => {
        this.setState({
            personNumber: '',
            personNumberFieldType: 'text',
        });
    };

    _personNumberBlur = () => {
        if (
            this.state.personNumber === null ||
            this.state.personNumber === ''
        ) {
            this.setState({
                personNumber: this.state.oldPersonNumber,
                personNumberFieldType: 'password',
            });
        }
    };

    render() {
        const { value, ...other } = this.props;

        return (
            <Dialog
                maxWidth="xs"
                aria-labelledby="confirmation-dialog-title"
                {...other}
            >
                <DialogTitle id="confirmation-dialog-title">
                    Edit User
                </DialogTitle>
                <DialogContent>
                    <ValidatorForm
                        className={styles.container}
                        style={{ textAlign: 'center' }}
                        onSubmit={this._handleEditSubmit}
                    >
                        <TextValidator
                            id="firstName"
                            label="First Name *"
                            className={styles.textField}
                            value={this.state.firstName}
                            style={{ margin: 5 }}
                            onChange={this._handleChange('firstName')}
                            validators={['required']}
                            errorMessages={['First Name is required']}
                        />
                        <TextValidator
                            id="lastName"
                            label="Last Name *"
                            className={styles.textField}
                            value={this.state.lastName}
                            style={{ margin: 5 }}
                            onChange={this._handleChange('lastName')}
                            validators={['required']}
                            errorMessages={['Last Name is required']}
                        />
                        <TextValidator
                            disabled
                            id="email"
                            label="E-Mail"
                            className={styles.textField}
                            value={this.state.email}
                            style={{ margin: 5 }}
                        />
                        <TextValidator
                            id="personNumber"
                            label="Person Number *"
                            className={styles.inputField}
                            value={this.state.personNumber}
                            style={{ margin: 5 }}
                            onFocus={this._personNumberFocused}
                            onBlur={this._personNumberBlur}
                            onChange={this._handleChange('personNumber')}
                            validators={['required']}
                            errorMessages={['Invalid Person Number']}
                            type={this.state.personNumberFieldType}
                        />
                        {!this.state.editingSelf && (
                            <div>
                                <div
                                    className={styles.inputField}
                                    style={{ marginTop: 10 }}
                                >
                                    Status:
                                    <Switch
                                        checked={this.state.isActive}
                                        onChange={this._handleStatusChange(
                                            'isActive'
                                        )}
                                        name="isActive"
                                    />
                                    {this.state.isActive
                                        ? 'Active'
                                        : 'InActive'}
                                </div>

                                <div
                                    style={{
                                        textAlign: 'center',
                                        display: 'inline-block',
                                    }}
                                >
                                    {this.state.userRoles
                                        .filter((role) => true)
                                        .map((role) => (
                                            <FormControlLabel
                                                key={role.RoleName}
                                                control={
                                                    <Checkbox
                                                        checked={role.Checked}
                                                        onChange={this._handleRoleChange(
                                                            role.RoleName
                                                        )}
                                                        value={role.RoleName}
                                                        style={{
                                                            color: '#B82601',
                                                        }}
                                                    />
                                                }
                                                label={role.RoleName}
                                                style={{
                                                    marginTop: 10,
                                                    marginLeft: 5,
                                                }}
                                            />
                                        ))}
                                    <p
                                        className="ehs-error"
                                        hidden={!this.state.roleValidationError}
                                        style={{ marginTop: '-10px' }}
                                    >
                                        You must select at least one role.
                                    </p>
                                </div>
                            </div>
                        )}
                        <div>
                            <Button
                                className="confirm embers"
                                style={{ marginTop: 10, marginRight: 15 }}
                                type="Submit"
                            >
                                Save
                            </Button>
                            <Button
                                className="cancel ink"
                                style={{ marginTop: 10 }}
                                onClick={this._handleCancel}
                                color="primary"
                            >
                                Cancel
                            </Button>
                        </div>
                    </ValidatorForm>
                </DialogContent>
            </Dialog>
        );
    }
}

export default withStyles(styles)(EditUserDialog);

import React from "react";
import EditIcon from "@material-ui/icons/Create";
import { Table, TableRow, TableHead, TableBody, Grid, TableCell, Hidden } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import AddIcon from "@material-ui/icons/Add";
import Button from '@material-ui/core/Button';
import { Redirect } from "react-router";
import { Delete as DeleteIcon } from "@material-ui/icons";
import moment from "moment";

import AlertDialog from "./../../AlertDialog";
import API from "./../../Components/api";

const styles = theme => ({
    root: {
        width: "100%",
        marginTop: theme.spacing.unit * 3,
        overflowX: "auto",
        display: "flex"
    },
    table: {
        minWidth: 700,
        display: "flex"
    }
});

class AssessmentGrid extends React.Component {

    state = {
        editRow: null
    };

    constructor(props) {
        super(props);
        this._rowId = 0;
        this.state = {
            assessments: this.props.assessments,
            promptDeleteAssessment: false,
            rowToDelete: null
        }

        this._openModal = this._openAssessment.bind(this);
        this._handleClose = this._handleClose.bind(this);
        this._addNewAssessment = this._addNewAssessment.bind(this);
        this._deleteAssessment = this._deleteAssessment.bind(this);
        this._renderTableContent = this._renderTableContent.bind(this);
        this._onAssessmentDeleteError = this._onAssessmentDeleteError.bind(this);
        this._onAssessmentDeleteSuccess = this._onAssessmentDeleteSuccess.bind(this);
        this._showDeleteAssessmentConfirmModal = this._showDeleteAssessmentConfirmModal.bind(this);
    }

    render() {
        if (this.state.editRow) {
            let newUrl = "/instructor/course-details/" + this.props.courseDetailId + "/assessment/" + this.state.editRow.Id;
            return <Redirect push={true} to={{ pathname: newUrl, 
                state:
                {
                    courseDetailId: this.props.courseDetailId, sourceData: this.state.editRow
                }
             }} />
        }

        if (this.state.promptDeleteAssessment) {
            return (
                <AlertDialog
                    title="Delete Assessment?"
                    content="Are you sure you want to delete this assessment?"
                    onOkClose={this._deleteAssessment}
                    onCancelClose={this._hideDeletePrompt}>
                </AlertDialog>
            )
        }

        return (
            <Paper className={styles.root}>
                <div style={{ paddingTop: "5pt" }}>
                    <Grid container justify="flex-start" alignItems="center" spacing={8}>
                        <Grid item xs={12} sm={6} md={6} style={{ paddingLeft: "15px", textAlign: "right" }}>
                            Assessments
                        </Grid>
                        <Grid item xs={12} sm={6} md={6} style={{ textAlign: "right" }}>
                            <Button className="ehs-add-button-wide" onClick={this._addNewAssessment} variant="contained" style={{ width: "100%", marginRight: "20px" }}>
                                <AddIcon className="white-text" style={{ marginRight: "theme.spacing.unit" }} />
                                <span className="nowrap white-text">Add New</span>
                            </Button>
                        </Grid>
                    </Grid>
                </div>
                {this._renderTableContent()}
            </Paper>
        )
    }

    _deleteAssessment() {
        var that = this;
        API.delete(`Assessment(${this.state.rowToDelete.Id})`)
            .then((res) => this._onAssessmentDeleteSuccess(that, res, this.state.rowToDelete.Id))
            .catch(this._onAssessmentDeleteError)
            .finally(this._hideDeletePrompt);
    }

    _onAssessmentDeleteSuccess(that, result, deletedId) {
        if (result.status === 200) {
            for (var idx = 0; idx < that.state.assessments.length; idx++) {
                if (that.state.assessments[idx].Id === deletedId) {
                    that.state.assessments.splice(idx, 1);
                    that.setState({});
                    break;
                }
            }
        }
        else
            alert("Unable to remove Assessment");
    }

    _onAssessmentDeleteError(err) {
        if (err && err.response && err.response.data && err.response.data.value)
            alert(err.response.data.value)
        else
            alert("Error: Unable to remove Assessment.");
    }

    _openAssessment(originalRow) {
        this.setState({
            editRow: originalRow
        });
    }

    _handleClose() {
        this.setState({
            editRow: null
        });
    }

    _addNewAssessment = () => {
        this.setState({
            editRow: {}
        });
    }

    _renderTableContent() {
        if (!this.state.assessments || this.state.assessments.length === 0)
            return <div style={{ width: "100%", textAlign: "center" }}>No Assessments have been created for this Course.</div>;

        let that = this;
        var totalAssessmentPercents = false;

        return (
            <Table className={styles.table}>
                <TableHead>
                    <TableRow selected>
                        <TableCell>Name</TableCell>
                        <Hidden xsDown={true}>
                            <TableCell>Assessment Type</TableCell>
                        </Hidden>
                        <Hidden xsDown={true}>
                            <TableCell>Due Date</TableCell>
                        </Hidden>
                        <Hidden mdDown={true}>
                            <TableCell padding="none">Weight</TableCell>
                        </Hidden>
                        <Hidden mdDown={true}>
                            <TableCell padding="none">Complete</TableCell>
                        </Hidden>
                        <Hidden mdDown={true}>
                            <TableCell padding="none">Marked (Count)</TableCell>
                        </Hidden>
                        <Hidden xsDown={true}>
                            <TableCell padding="none">GAIs</TableCell>
                        </Hidden>
                        <TableCell></TableCell>
                        <TableCell></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {this.state.assessments.map(function (row) {
                        let assessmentDueDate = "not specified";

                        if (row.AssessmentDueDate) {
                            assessmentDueDate = moment.utc(row.AssessmentDueDate).format("MMMM D, YYYY");
                        }

                        totalAssessmentPercents += row.Weight;

                        return (
                            <TableRow key={row.Id}>
                                <TableCell component="th" scope="row">{row.Title}</TableCell>
                                <Hidden xsDown={true}>
                                    <TableCell>{row.AssessmentType.Title}</TableCell>
                                </Hidden>
                                <Hidden xsDown={true}>
                                    <TableCell>{assessmentDueDate}</TableCell>
                                </Hidden>
                                <Hidden mdDown={true}>
                                    <TableCell padding="none">{row.Weight}%</TableCell>
                                </Hidden>
                                <Hidden mdDown={true}>
                                    <TableCell padding="none">{row.IsComplete ? "Y" : "N"}</TableCell>
                                </Hidden>
                                <Hidden mdDown={true}>
                                    <TableCell padding="none">{row.Marked}</TableCell>
                                </Hidden>
                                <Hidden xsDown={true}>
                                    <TableCell padding="none">
                                        {that._renderGAIs(row.GAIs)}
                                    </TableCell>
                                </Hidden>
                                <TableCell>
                                    <EditIcon style={{ color: "black" }} className="actionIcon" onClick={() => that._openAssessment(row)}></EditIcon>
                                </TableCell>
                                <TableCell>
                                    <DeleteIcon style={{ color: "black" }} className="actionIcon" onClick={() => that._showDeleteAssessmentConfirmModal(row)}></DeleteIcon>
                                </TableCell>
                            </TableRow>
                        );
                    })}
                    {this._renderWarningRow(totalAssessmentPercents)}
                </TableBody>
            </Table>
        )
    }

    _renderWarningRow(totalAssessmentPercents) {
        if (totalAssessmentPercents <= 100.0)
            return;

        return (
            <TableRow>
                <TableCell colSpan={7} className="oxblood whiteText right">
                    The Weight of all Assessments exceeds 100%
                </TableCell>
            </TableRow>);
    }

    _renderGAIs(arr) {
        if (!arr)
            return "";

        let toReturn = "";

        for (var idx = 0; idx < arr.length; idx++) {
            if (toReturn !== "")
                toReturn += ", ";

            toReturn += arr[idx].Number;
        }

        return toReturn;
    }

    /**
    * Prompt the User to confirm if they want to delete the Assessment
    */
    _showDeleteAssessmentConfirmModal(row) {
        this.setState({ promptDeleteAssessment: true, rowToDelete: row });
    }

    _hideDeletePrompt = () => {
        this.setState({ promptDeleteAssessment: false });
    }
}

export default AssessmentGrid;
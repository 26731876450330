import React from "react";
import { Switch, Route } from "react-router-dom";
import { Redirect } from "react-router";

import TermCourses from "./components/Instructor/TermCourses";
import { SignIn } from "./components/SignIn";
import Landing from "./components/MainPage/Landing";
import Header from "./components/MainPage/Header";
import CourseDetails from "./components/Instructor/CourseDetails";
import AssessmentDetails from "./components/Assessment/AssessmentDetails";
import AdminOverview from "./components/Admin/AdminOverview";
import Marks from "./components/Assessment/Marks";
import API from "./components/Components/api";
import InternetExplorerAlert from "./components/Components/InternetExplorerAlert";
import MyProfile from "./components/MyProfile";
import PasswordPage from "./components/PasswordPage";
import ForgotPassword from "./components/ForgotPassword";
import ResetPassword from "./components/ResetPassword";
import UserProfile from "./components/Admin/UserProfile";
import noImage from "./../src/img/no-profile.png";
import WaitSpinner from "./components/Components/WaitSpinner";

class App extends React.Component {
    displayName = App.name;

    constructor(props) {
        super(props);

        this.state = {
            configLoaded: false,
            goHome: false,
            goToUserProfile: false,
            goToPassword: false
        };
    }

    UNSAFE_componentWillMount() {
        this._setAPIUrl();
    }

    _setAPIUrl() {
        var that = this;
        fetch("api/Configuration/GetConfig")
            .then(res => res.json())
            .then(
                (result) => {
                    if (result && result.apiURL) {
                        API.setBaseURL(result.apiURL);
                        that.setState({ configLoaded: true });
                    }
                },
                // Note: it's important to handle errors here
                // instead of a catch() block so that we don't swallow
                // exceptions from actual bugs in components.
                (error) => {
                }
            )
    }

    render() {
        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        if (!this.state.configLoaded)
            return (<WaitSpinner></WaitSpinner>);

        if (window.location.pathname === "/ForgotPassword") {
            return <ForgotPassword />;
        } else if (window.location.pathname === "/ResetPassword") {
            return <ResetPassword />;
        } else if (!authToken) {
            return (
                <React.Fragment>
                    <SignIn onLogin={this._login} />
                    <InternetExplorerAlert />
                </React.Fragment>
            );
        } else {
            if (this.state.goHome) {
                this.setState({ goHome: false });
                return <Redirect to="/" push />;
            }

            if (this.state.goToUserProfile) {
                this.setState({ goToUserProfile: false });
                return <Redirect to="/profile" push />;
            }

            if (this.state.goToPassword) {
                this.setState({ goToPassword: false });
                return <Redirect to="/password" push />;
            }

            return (
                <React.Fragment>
                    <Header
                        goToUserProfile={this.goToUserProfile}
                        goToPassword={this.goToPassword}
                        goHome={this.goHome}
                    />
                    <Switch>
                        <Route exact path="/" component={Landing} />
                        <Route
                            path="/profile"
                            render={props => (
                                <MyProfile
                                    {...props}
                                    onProfilePictureChanged={this._updateProfilePicture}
                                />
                            )}
                        />
                        <Route
                            path="/userprofile/:personId"
                            render={props => (
                                <UserProfile
                                    {...props}
                                />
                            )}
                        />
                        <Route path="/password" component={PasswordPage} />
                        <Route path="/admin" component={AdminOverview} />
                        <Route path="/terms" component={TermCourses} />
                        <Route
                            exact
                            path="/instructor/course-details/:id"
                            component={CourseDetails}
                        />
                        <Route
                            exact
                            path="/instructor/course-details/:id/assessment/:id1"
                            render={props => <AssessmentDetails {...props} />}
                        />
                        <Route
                            exact
                            path="/instructor/course-details/assessment/:id1/marks"
                            render={props => <Marks {...props} />}
                        />
                    </Switch>
                    <InternetExplorerAlert />
                </React.Fragment>
            );
        }
    }

    _updateProfilePicture = () => {
        this.setState({});
    };

    goHome = () => {
        this.setState({ goHome: true });
    };

    goToUserProfile = () => {
        this.setState({ goToUserProfile: true });
    };

    goToPassword = () => {
        // Redirect here
        this.setState({ goToPassword: true });
    };

    _login = res => {
        localStorage.setItem("RefreshToken", res.refresh_token);
        localStorage.setItem("AuthToken", res.access_token);
        localStorage.removeItem("ProfilePicture");

        var authToken = localStorage.getItem("AuthToken");
        if (authToken === 'undefined') authToken = undefined;

        let base64Url = authToken.split(".")[1];
        let base64 = base64Url.replace("-", "+").replace("_", "/");
        let tokenInfo = JSON.parse(window.atob(base64));
        let personIdLoggedIn = parseInt(tokenInfo.PersonId, 10);
        let instLogo = tokenInfo.InstLogo;
        let instName = tokenInfo.InstName;
        let prevLogIn = localStorage.getItem("PersonId");

        if (prevLogIn !== personIdLoggedIn)
            this._resetNavCache();

        localStorage.setItem("PersonId", personIdLoggedIn);
        localStorage.setItem("InstLogo", instLogo);
        localStorage.setItem("InstName", instName);

        API.get(`Auth/GetProfilePicture()`)
            .then(this._storeProfilePicture)
            .catch(this._errorGetProfilePicture);
    };

    //Reset cache if different person logged in
    _resetNavCache = () => {
        localStorage.removeItem("academicTermId");
        localStorage.removeItem("ADMINTAB");
    }

    /**
     * Store picture in localStorage
     * @param {*} response API response
     */
    _storeProfilePicture = response => {
        localStorage.setItem(
            "ProfilePicture",
            response.status === 200 ? response.data.value : noImage
        );
        this.setState({});
    };

    /**
     * Deal with error getting picture
     * @param {*} e Error from get picture API call
     */
    _errorGetProfilePicture = e => {
        // TODO: Something
        localStorage.removeItem("ProfilePicture");
        localStorage.setItem("ProfilePicture", noImage);
        this.setState({});
    };
}

export default App

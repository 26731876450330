/**
 * Renders Assessment GAIs
 */

import React from "react";
import EditIcon from "@material-ui/icons/Create";
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import ViewIcon from "@material-ui/icons/Visibility";
import Button from "@material-ui/core/Button";

import QuestionEditDialog from "../Instructor/Course/QuestionEditDialog";
import "./QuestionGrid.css";
import API from "./../Components/api";
import AlertDialog from "./../AlertDialog";

const styles = (theme) => ({
  root: {
    width: "100%",
    marginTop: theme.spacing.unit * 3,
    overflowX: "auto",
  },
  table: {
    maxWidth: "100%",
  },
});

class QuestionGrid extends React.Component {
  constructor(props) {
    super(props);
    this._rowId = 0;
    this._totalGAIs = 0;

    this.state = {
      questions: [],
      editRow: null,
    };

    this._renderQuestions = this._renderQuestions.bind(this);
    this._getAssessmentQuestions = this._getAssessmentQuestions.bind(this);
    this._handleClose = this._handleClose.bind(this);
  }

  componentDidMount() {
    this._getAssessmentQuestions();
  }

  render() {
    if (this.state.promptDeleteGai) {
      return (
        <AlertDialog
          title="Delete GAI and related data?"
          content="Are you sure you want to delete this GAI?"
          onOkClose={this._deleteGai}
          onCancelClose={this._hideDeletePrompt}
        ></AlertDialog>
      );
    }

    if (this.state.editRow) {
      return (
        <QuestionEditDialog
          assessmentId={this.props.assessmentId}
          onClose={this._handleClose}
          sourceData={this.state.editRow}
          academicTermId={this.props.academicTermId}
          courseInstanceId={this.props.courseInstanceId}
          isDisabled={this.props.isDisabled}
          programs={this.props.programs}
          academicYearId={this.props.academicYearId}
        />
      );
    }

    return (
      <Paper className={styles.root}>
        <div
          style={{
            textAlign: "center",
            paddingTop: "5px",
            paddingBottom: "25px",
          }}
        >
          GAI's Assigned
          {!(this.props.isDisabled && this.props.isDisabled === true) && (
            <Button
              className={"ehs-add-button-wide ehs-position-right "}
              onClick={this._addNew}
              variant="contained"
            >
              <AddIcon
                className="nowrap white-text"
                style={{ marginRight: "theme.spacing.unit" }}
              />
              <span className="nowrap white-text">Add New</span>
            </Button>
          )}
        </div>
        {this._renderQuestions()}
      </Paper>
    );
  }

  /**
   * Get Assessment Questions from API
   */
  _getAssessmentQuestions() {
    var that = this;
    that._totalGAIs = 0;

    API.get(`Assessment(${this.props.assessmentId})?$expand=Questions($expand=GAI,ContentLevel,Alignment)`)
    .then(function (res) {
        that.setState({
        questions: res.data.Questions.map((x) => {
          let res = (x.MarkedOutOf / that.props.maxAssessmentScore) * 100;

          //Calculate assessment question weight compareed to the entire assessment weight
          const weight = Math.round(res * 100) / 100;
          x["weight"] = weight + "%";
          that._totalGAIs = that._totalGAIs + weight;
          return x;
        }),
      });
    });
  }

  _renderWarningRow() {
    if (this._totalGAIs <= 100) return;

    return (
      <TableRow>
        <TableCell colSpan={7} className="oxblood whiteText right">
          The Weight of all GAIs exceeds 100%
        </TableCell>
      </TableRow>
    );
  }
  /**
   * Render Questions
   */
  _renderQuestions = () => {
    if (!this.state.questions || this.state.questions.length > 0) {
      let that = this;

      return (
        <Table className={styles.table}>
          <TableHead>
            <TableRow selected>
              <TableCell style={{ width: "50px" }}>GAI</TableCell>
              <TableCell padding="none">Level</TableCell>
              <TableCell padding="none">Alignment</TableCell>
              <TableCell padding="none">Weight (% of Assessment)</TableCell>
              <TableCell padding="none">Max Score</TableCell>
              <TableCell padding="none">Marked (Count)</TableCell>
              <TableCell
                className={
                  this.props.isDisabled && this.props.isDisabled === true
                    ? "hide"
                    : ""
                }
              ></TableCell>
              <TableCell
                className={
                  this.props.isDisabled && this.props.isDisabled === true
                    ? "hide"
                    : ""
                }
              ></TableCell>
              <TableCell
                className={
                  this.props.isDisabled && this.props.isDisabled === true
                    ? ""
                    : "hide"
                }
              ></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {this.state.questions.map(function (row) {
              return (
                <React.Fragment key={row.AssessmentQuestionId}>
                  <TableRow>
                    <TableCell>
                      {row.GAI === undefined || row.GAI === null
                        ? ""
                        : row.GAI.Number}
                    </TableCell>
                    <TableCell padding="none">
                      {that._extractAttributeValue(row.ContentLevel, "Name")}
                    </TableCell>
                    <TableCell padding="none">
                      {that._extractAttributeValue(row.Alignment, "Name")}
                    </TableCell>
                    <TableCell padding="none">{row.weight}</TableCell>
                    <TableCell padding="none" style={{ width: "150px" }}>
                      {row.MarkedOutOf}
                    </TableCell>
                    <TableCell padding="none">{row.Marked}</TableCell>
                    <TableCell
                      className={
                        "icon " +
                        (that.props.isDisabled && that.props.isDisabled === true
                          ? "hide"
                          : "")
                      }
                    >
                      <EditIcon
                        style={{ color: "black" }}
                        className="actionIcon"
                        onClick={() => that._openQuestion(row)}
                      ></EditIcon>
                    </TableCell>
                    <TableCell
                      className={
                        "icon " +
                        (that.props.isDisabled && that.props.isDisabled === true
                          ? "hide"
                          : "")
                      }
                    >
                      <DeleteIcon
                        style={{
                          color: "black",
                          marginRight: "theme.spacing.unit",
                        }}
                        className="actionIcon"
                        onClick={() =>
                          that._showDeleteGaiConfirmModal(
                            row.AssessmentQuestionId
                          )
                        }
                      />
                    </TableCell>
                    <TableCell
                      className={
                        "icon " +
                        (that.props.isDisabled && that.props.isDisabled === true
                          ? ""
                          : "hide")
                      }
                    >
                      <ViewIcon
                        style={{
                          color: "black",
                          marginRight: "theme.spacing.unit",
                        }}
                        className="actionIcon"
                        onClick={() => that._openQuestion(row)}
                      />
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell style={{ paddingLeft: "0" }} colSpan={6}>
                      {row.QuestionText}
                    </TableCell>
                  </TableRow>
                </React.Fragment>
              );
            })}
            {this._renderWarningRow()}
          </TableBody>
        </Table>
      );
    }

    return (
      <div className="full-width center">
        No GAIs have been assigned to this Assessment.
      </div>
    );
  };

  _extractAttributeValue(row, attrName) {
    return row && row[attrName] ? row[attrName] : "N/A";
  }

  _addNew = () => {
    this.setState({
      editRow: {},
    });
  };

  _handleClose = () => {
    this.setState({
      editRow: null,
    });

    this._getAssessmentQuestions();

    if (this.props.onGaiChanged) this.props.onGaiChanged();
  };

  _openQuestion(originalRow) {
    this.setState({
      editRow: originalRow,
    });
  }

  /**
   * Prompt the User to confirm if they want to delete the GAI
   */
  _showDeleteGaiConfirmModal(assessmentQuestionId) {
    this.setState({ promptDeleteGai: true, gaiId: assessmentQuestionId });
  }

  _hideDeletePrompt = () => {
    this.setState({ promptDeleteGai: false, gaiId: null });
  };

  _deleteGai = () => {
    API.delete(`AssessmentGai(${this.state.gaiId})`)
      .then(this._onGaiDeleteOk)
      .catch(this._onGaiDeleteError)
      .finally(this._hideDeletePrompt);
  };

  _onGaiDeleteOk = (res) => {
    if (res.status === 200) {
      this._getAssessmentQuestions();

      if (this.props.onGaiChanged) this.props.onGaiChanged();
    } else alert("Error: Unable to delete GAI.");
  };

  _onGaiDeleteError = (err) => {
    if (err.response.status === 400 && err.response.data.value)
      alert(err.response.data.value);
    else alert("Error: Unable to delete GAI.");
  };
}

export default QuestionGrid;
